import React, { useEffect, useRef } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Select, MenuItem, TextField } from "@mui/material"
import * as styles from "./style.module.scss"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import AppManager from "services/app-manager"

const useStyles = () => ({
  inputField: {
    width: "100%",
    height: "56px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: useMediaQuery("(min-width:768px)") ? "24px" : "20px",
    color: "#202020",
    paddingLeft: "10px",
  }
})

const InputFilter = (
  props: { setDepositAmount: (depositAmount: number) => void, setDepositTerm: (depositTerm: number) => void, disableDropdown?: boolean }
) => {
  const classes = useStyles()
  const termList = ["1 month", "3 months", "6 months", "9 months", "12 months", "18 months", "24+ months"]
  const loanAmountRef = useRef<HTMLInputElement>()
  const appManager = AppManager.getInstance()

  useEffect(() => {
    window.onscroll = () => {
      loanAmountRef.current?.blur()
    }
  }, [])

  const formatLoanAmount = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = Number(e.target.value.replace(/\D+/g, ''))
    e.target.value = "$" + value.toLocaleString()
  }

  const updateDepositAmount = (v: string) => {
    const value = Number(v.replace(/\D+/g, ''))
    appManager.setDepositInvestmentAmount(value)
    props.setDepositAmount(value)
    DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_FILTER_INPUT, { action: "loanDeposit", value: v })
  }

  const updateDepositTerm = (v: string) => {
    const value = Number(v.replace(/\D+/g, ''))
    appManager.setDepositInvestmentTerm(value)
    props.setDepositTerm(value)
    DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_FILTER_INPUT, { action: "loanDeposit", value: v })
  }

  const InputFilterDropDown = (dropDownList: string[], value: string, setValue: (value: string) => void) => {
    return (
      <div>
        <Select
          value={value}
          variant="standard"
          disableUnderline
          disabled={props.disableDropdown}
          // className={styles.inputField}
          style={classes.inputField}
          // input={fontSize:2}
          MenuProps={{
            disableScrollLock: true,
          }}
          onChange={(e) => setValue(e.target.value)}
        >
          {dropDownList.map((o, i) => {
            return <MenuItem value={o} key={`input-item-${o}`}>{o}</MenuItem>
          })}
        </Select>
      </div>
    )
  }

  const selectedTerm = appManager.getDepositInvestmentTerm()

  return (
    <div>
      <div className={styles.inputFilterHeader}>
        Enter scenario to see relevant rates
      </div>
      <div className={styles.inputFilterContainer}>
        <div className={styles.inputCellContainer}>
          <div className={styles.inputLabel}>I want to invest</div>
          <div className={styles.inputFieldContainer}>
            <TextField
              defaultValue={"$" + appManager.getDepositInvestmentAmount().toLocaleString()}
              variant="standard"
              inputRef={loanAmountRef}
              InputProps={{
                disableUnderline: true,
                style: classes.inputField,
                // className: styles.inputField,
              }}
              onFocus={e => setTimeout(() => {
                const l = e.target.value.length
                const start = e.target.selectionStart || 0
                const end = Math.max(start, e.target.selectionEnd || 0)
                e.target.value = e.target.value.replace(/\D+/g, '')
                e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
              }, 0)}
              onChange={(e) => {
                const l = e.target.value.length
                const start = e.target.selectionStart || 0
                const end = Math.max(start, e.target.selectionEnd || 0)
                e.target.value = Number(e.target.value.replace(/\D+/g, '')).toString()
                e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Escape") {
                  (e.target as EventTarget & HTMLDivElement).blur()
                }
              }}
              onBlur={(e) => {
                formatLoanAmount(e)
                updateDepositAmount(e.target.value)
              }}
            />
          </div>
        </div>
        <div className={styles.inputCellContainer}>
          <div className={styles.inputLabel}>for about</div>
          <div className={styles.inputFieldContainer}>
            {InputFilterDropDown(termList, selectedTerm.toString() + (selectedTerm === 24 ? '+' : '')
              + " month" + (selectedTerm > 1 ? "s" : ''), updateDepositTerm)
            }
          </div>
        </div>
      </div>
    </div>
  )

}

export default InputFilter